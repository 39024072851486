var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipMSPPage app h5MaxWidth"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"vipMSP"},[_c('div',{staticClass:"upgradeVip"},[_c('div',{staticClass:"vipContent",attrs:{"id":"vipContent"}},[_c('ul',[_c('li',{key:"1"},[_vm._m(1)]),_c('li',{key:"2"},[_vm._m(2)]),_c('li',{key:"3"},[_vm._m(3)]),_c('li',{key:"4"},[_vm._m(4)]),_c('li',{key:"5"},[_vm._m(5)]),_c('li',{key:"6"},[_vm._m(6)]),_c('li',{key:"7"},[_vm._m(7)]),_c('li',{key:"8"},[_vm._m(8)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"header h5MaxWidth clear display alignCenter"},[_c('div',{staticClass:"back display alignCenter justifyCenter",attrs:{"onclick":"window.history.go(-1)"}},[_c('i',{staticClass:"iconfont icon-arrow-left"})]),_c('h2',{staticClass:"flex1"},[_vm._v("VIP level description")]),_c('div',{staticClass:"navskip",attrs:{"id":"sideMenu"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v1"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("395")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv1.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v2"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("10,000")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv2.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v3"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("21,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv3.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v4"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("71,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv4.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v5"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("207,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv5.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v6"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("597,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv6.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v7"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("977,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv7.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCard radius15PX display alignStart v8"},[_c('div',{staticClass:"explain flex1"},[_c('p',[_vm._v(" This level of VIP requires a total investment amount of "),_c('b',[_vm._v("1,777,777")]),_vm._v(" rupees ")])]),_c('div',{staticClass:"vipLevelMark display alignCenter"},[_c('div',{staticClass:"vip_ICON radius50P"},[_c('img',{attrs:{"src":require("./../images/lv8.svg"),"alt":""}})])])])
}]

export { render, staticRenderFns }