<template>
  <div class="vipMSPPage app h5MaxWidth">
    <header>
      <div class="header h5MaxWidth clear display alignCenter">
        <div
          class="back display alignCenter justifyCenter"
          onclick="window.history.go(-1)"
        >
          <i class="iconfont icon-arrow-left"></i>
        </div>
        <h2 class="flex1">VIP level description</h2>
        <div class="navskip" id="sideMenu"></div>
      </div>
    </header>

    <div class="main">
      <div class="vipMSP">
        <div class="upgradeVip">
          <div class="vipContent" id="vipContent">
            <ul>
              <li key="1">
                <div class="vipCard radius15PX display alignStart v1">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>395</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv1.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="2">
                <div class="vipCard radius15PX display alignStart v2">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>10,000</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv2.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="3">
                <div class="vipCard radius15PX display alignStart v3">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>21,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv3.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="4">
                <div class="vipCard radius15PX display alignStart v4">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>71,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv4.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="5">
                <div class="vipCard radius15PX display alignStart v5">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>207,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv5.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="6">
                <div class="vipCard radius15PX display alignStart v6">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>597,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv6.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="7">
                <div class="vipCard radius15PX display alignStart v7">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>977,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv7.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>

              <li key="8">
                <div class="vipCard radius15PX display alignStart v8">
                  <div class="explain flex1">
                    <p>
                      This level of VIP requires a total investment amount of
                      <b>1,777,777</b> rupees
                    </p>
                  </div>
                  <div class="vipLevelMark display alignCenter">
                    <div class="vip_ICON radius50P">
                      <img src="./../images/lv8.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="less">
@charset "utf-8";

.about {
  padding: 10px;
  background-color: #fff;
}

.headline {
  margin: 0 0 20px;
}

.headline h3 {
  line-height: 1.2;
  font-size: 26px;
  color: var(--black0);
}

.textart {
  margin: 20px 0;
}

.textart h3 {
  font-size: 22px;
  color: var(--black0);
}

.textart h4 {
  margin: 10px 0 0;
  font-size: 18px;
  color: var(--black0);
}

.textart p {
  padding: 5px 0;
  font-size: 16px;
  color: var(--black1);
  line-height: 1.2;
}

.textart p b {
  margin: 0 10px 0 0;
  font-size: 16px;
  color: var(--black0);
}

.textart img {
  width: 100%;
}

.textart ul li {
  margin: 5px 0;
  padding: 5px 0;
}

.textart ul li h4,
.textart ul li p {
  margin: 0;
  padding: 0;
}

/* VIP Card */
.vipMSP {
  padding: 10px;
}

.vipContent ul > li {
  position: relative;
}

.vipCard {
  margin: 20px 0 0;
  padding: 10px 20px;
  background-color: #fff;
}

.vipCard .explain {
  margin: 0;
  padding: 0 40px 0 0;
}

.vipLevelMark {
  background-color: transparent;
}

.vipLevelMark .vip_ICON {
  width: 65px;
  height: initial;
}
</style>
